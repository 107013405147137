import { createStore } from 'redux'

const initialState = {
    loading: false, profileId: 0, alert: {
        show: false,
        text: "none",
        color: "primary",
        fixed: false
    },
    path: "/", access_token: null, menu: [], urlSearch: "", idCandidate: -1
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case 'LOADING':
            return { ...state, loading: action.payload.show }
        case "ADD_PROFILE":
            return { ...state, profileId: action.payload }
        case "ALERT":
            return { ...state, loading: false, alert: action.payload }
        case "UNMOUNT_ALERT":
            return { ...state, alert: { ...state.alert, show: false } }
        case "PATH":
            return { ...state, path: action.payload }
        case "SIGNOUT":
            return { ...state, login: "", access_token: null }
        case "MENU":
            return { ...state, menu: action.payload.menu, profileId: action.payload.profileId }
        case 'ACCESS_TOKEN':
            return { ...state, access_token: action.payload }
        case "URL_SEARCH":
            return { ...state, urlSearch: action.payload }
        case 'IDCANDIDATE':
            return { ...state, idCandidate: action.payload }
        default:
            return state;
    }
}

const store = createStore(reducer)

export default store