export const Loading = (show) => {
    return { type: "LOADING", payload: { show } }
}

export const Toast = (text, type, fixed) => {
    if (type === undefined) type = ToastTypes.Info
    if (fixed === undefined) fixed = false

    return { type: "ALERT", payload: { show: true, text: text, color: type, fixed: fixed } }
}

export const ToastTypes = {
    Success: "success",
    Danger: "danger",
    Warning: "warning",
    Info: "info"
}

export const HideToast = () => {
    return { type: "UNMOUNT_ALERT" }
}

export const GetParameter = (parameter) => {
    const urlParams = new URLSearchParams(document.location.search)
    return urlParams.get(parameter)
}

export const DeleteFileAjax = (username, fileId) => {

    const requestOptions = { method: "DELETE", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ IdFile: fileId, LogUser: username }) }

    fetch("http://pt-intranet:9008/api/UploadFiles", requestOptions).catch(error => console.error(error))
}

export const APPCONFIG = {
    FileServiceUrl: "http://pt-intranet.corp.pri:9008/api/uploadfiles",
    HashAppId: "da7adac7-0d00-4977-b44c-0b1cdb814448",
    FileUrl: "http://pt-intranet.corp.pri:9008/OUT/FilesExport/"
}

export function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

export function formatDate(date) {
    if (date === null || date === undefined)
        return ""

    var d = new Date(date.toString()),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function addDays(date, days) {
    let someDate = date === "" || date === null ? new Date() : date
    let copiedDate = new Date(someDate.getTime())

    copiedDate.setDate(copiedDate.getDate() + days)

    return copiedDate
}

export function zeroPad(num, places) {
    return String(num).padStart(places, '0')
}

export function formatHour(hour) {
    return [zeroPad(hour.toString().slice(0, hour.toString().length - 2).toString(), 2), ':', zeroPad(hour.toString().slice(hour.toString().length - 2).toString(), 2)].join('');
}

export function ValidateEmail(inputText) {
    return inputText.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
}

export function getStrWeek(intWeek) {
    
    var strWeek;

    switch (intWeek) {
        case 1:
            strWeek = 'Segunda'
            break;
        case 2:
            strWeek = 'Terca'
            break;
        case 3:
            strWeek = 'Quarta'
            break;
        case 4:
            strWeek = 'Quinta'
            break;
        case 5:
            strWeek = 'Sexta'
            break;
        case 6:
            strWeek = 'Sabado'
            break;
        default:
            strWeek = 'Domingo'
    }
    return strWeek;
}

export function IsNumeric(ObjVal) {
    return /^\d+$/.test(ObjVal);
}

export function IsValidNIF(nif) {
    var c;
    var checkDigit = 0;
    //Verifica se � null, se � num�rico e se tem 9 d�gitos
    if (nif !== null && IsNumeric(nif) && nif.length === 9 && nif !== '123456789') {
        
        //Obtem o primeiro n�mero do NIF
        c = nif.charAt(0);
        //Verifica se o primeiro n�mero � (1, 2, 5, 6, 8 ou 9)
        if (c.trim() === '1' || c.trim() === '2' || c.trim() === '3') {
            //Calculo do Digito de Controle
            checkDigit = c * 9;
            var i = 0;
            for (i = 2; i <= 8; i++) {
                checkDigit += nif.charAt(i - 1) * (10 - i);
            }
            checkDigit = 11 - (checkDigit % 11);

            //Se o digito de controle � maior que dez, coloca-o a zero
            if (checkDigit >= 10)
                checkDigit = 0;

            //Compara o digito de controle com o �ltimo numero do NIF
            //Se igual, o NIF � v�lido.
            if (parseInt(checkDigit) === parseInt(nif.charAt(8)))
                return true;
        }
    }
    return false;
}

export function maskPostalCode(PostalCode) {

    if (PostalCode === undefined || PostalCode == null)
        return ''

    if (PostalCode.length > 4) {
        if (PostalCode.charAt(PostalCode.length - 1).trim() === '-')
            return PostalCode.toString() 
        else {
            PostalCode = PostalCode.replace('-', '')
            return PostalCode.substring(0, 4) + '-' + PostalCode.substring(4)
        }
    }
    else if (PostalCode.length <= 4 && isNaN(PostalCode) === false) {
           return PostalCode
    }
}

export function CheckPostalCode(postalCode, FullPostalCode) {
    if (FullPostalCode)
        return /^\d{4}-\d{3}?$/.test(postalCode)
    else
        return /^\d{4}(-\d{3})?$/.test(postalCode)
}

export function DateUtc(value) {
    return new Date(Date.UTC(new Date(value).getFullYear(), new Date(value).getMonth(), new Date(value).getDate()))
}