import React from 'react'
import { Button, Row, Col, Input, Label } from 'reactstrap';
import { connect } from "react-redux"
import { Toast, ToastTypes, Loading, formatDate, IsValidNIF, IsNumeric, maskPostalCode, CheckPostalCode, GetParameter,DateUtc } from "./global/Utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"


class InfoResume extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            token : '',
            literaryAbilities: [],
            studyAreas: [],
            nationalities: [],
            documentType: [],
            attendanceYear: [],
            ObjData: {},
            ObjDataWorkflow: [],
            ObjError: {
                name: false, email: false, phoneNumber: false, birthDate: false, address: false,
                postalCode: false, postalLocation: false, idCitizenship: false, fiscalNumber: false, niss: false,
                idDocumentType: false, documentNumber: false, documentExpirationDate: false, idLiteraryQualification: false,
                idStudyArea: false
            }
        }
    }

    componentDidMount() {

        if (GetParameter("token") === undefined)
            return;
        else
            this.setState({ token: GetParameter("token") })

        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("api/literaryabilities", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ literaryAbilities: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos das habilitações literárias", ToastTypes.Danger, false)))

        fetch("api/studyareas", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ studyAreas: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos das àreas de estudo", ToastTypes.Danger, false)))

        fetch("api/nationalities", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ nationalities: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos das nacionalidades", ToastTypes.Danger, false)))

        fetch("api/documentType", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ documentType: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os tipos de documentos", ToastTypes.Danger, false)))

        fetch("api/attendanceyear", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ attendanceYear: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os anos de frequência", ToastTypes.Danger, false)))

        fetch("api/candidatepersonaldata?idCandidate=" + parseInt(this.props.idCandidate), requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json.personalData.postalCode !== null && json.personalData.postalCode !== undefined)
                    json.personalData.postalCode = maskPostalCode(json.personalData.postalCode)

                this.setState({ ObjData: json.personalData, ObjDataWorkflow: json.workflow })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter a informação relativa ao candidato.", ToastTypes.Danger, false)))


        this.props.dispatch(Loading(false))

    }

    handleChangeForm(e, DateId) {
        let dataValue = DateId !== undefined ? e : e.target.value.toUpperCase()
        var id = DateId !== undefined ? DateId : e.target.id

        if (id !== 'AttendanceYear') {

            if (id === 'postalCode') {
                dataValue = maskPostalCode(dataValue)
                this.setState({ ObjData: { ...this.state.ObjData, [id]: dataValue }, ObjError: { ...this.state.ObjError, [id]: CheckPostalCode(dataValue,true) === false ? true : false } })
            }
            else if (id === 'niss') {
                this.setState({ ObjData: { ...this.state.ObjData, [id]: dataValue }, ObjError: { ...this.state.ObjError, [id]: dataValue.length !== 11 ? true : false } })
            }
            else {
                this.setState({ ObjData: { ...this.state.ObjData, [id]: dataValue }, ObjError: { ...this.state.ObjError, [id]: dataValue === '' || dataValue === -1 ? true : false } })
            }

            
        }
        else {
            var objWorkflow = []
            var objDetail = {};
            this.state.ObjDataWorkflow.forEach(a => {
                var aux = a
                objDetail = a.detail;
                objDetail = { ...objDetail, attendanceYear: parseInt(dataValue) }
                aux = { ...aux, detail: objDetail }
                objWorkflow.push(aux)
            })
            this.setState({ ObjDataWorkflow: objWorkflow })
        }
    }

    handleSave() {

        if (this.state.token === undefined || this.state.token === null || this.state.token === '') {
            this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde", ToastTypes.Danger, false))
            return
        }

        var error = this.state.ObjError

        error.name = this.state.ObjData.name === '' || this.state.ObjData.name === null
        error.email = this.state.ObjData.email === '' || this.state.ObjData.email === null
        error.phoneNumber = this.state.ObjData.phoneNumber === '' || this.state.ObjData.phoneNumber === null
        error.birthDate = this.state.ObjData.birthDate === '' || this.state.ObjData.birthDate === null
        error.address = this.state.ObjData.address === '' || this.state.ObjData.address === null
        error.postalCode = CheckPostalCode(this.state.ObjData.postalCode, true) === false ? true : false
        error.postalLocation = this.state.ObjData.postalLocation === '' || this.state.ObjData.postalLocation === null
        error.idCitizenship = this.state.ObjData.idCitizenship === -1 || this.state.ObjData.idCitizenship === null
        error.fiscalNumber = this.state.ObjData.fiscalNumber === '' || this.state.ObjData.fiscalNumber === null || !IsValidNIF(this.state.ObjData.fiscalNumber)
        error.niss = this.state.ObjData.niss === '' || this.state.ObjData.niss === null || !IsNumeric(this.state.ObjData.niss) || this.state.ObjData.niss.length !== 11
        error.idDocumentType = this.state.ObjData.idDocumentType === -1 || this.state.ObjData.idDocumentType === null
        error.documentNumber = this.state.ObjData.documentNumber === '' || this.state.ObjData.documentNumber === null
        error.documentExpirationDate = this.state.ObjData.documentExpirationDate === '' || this.state.ObjData.documentExpirationDate === null
        error.idLiteraryQualification = this.state.ObjData.idLiteraryQualification === -1 || this.state.ObjData.idLiteraryQualification === null
        error.idStudyArea = this.state.ObjData.idStudyArea === -1 || this.state.ObjData.idStudyArea === null

        this.setState({ ObjError: error })

        var hasError = false
        for (var propt in error) {
            if (error[propt])
                hasError = true
        }

        if (hasError) {
            this.props.dispatch(Toast("Por favor preencha todos os campos", ToastTypes.Warning, false))
            return
        }
            

        var objPersonalData = {
            IdCandidate: parseInt(this.props.idCandidate),
            IdWorkflow: -1,
            Name: this.state.ObjData.name,
            PhoneNumber: this.state.ObjData.phoneNumber,
            Email: this.state.ObjData.email,
            BirthDate: DateUtc(this.state.ObjData.birthDate),
            Address: this.state.ObjData.address,
            PostalCode: this.state.ObjData.postalCode.toString().replace('-',''),
            PostalLocation: this.state.ObjData.postalLocation,
            IdCitizenship: this.state.ObjData.idCitizenship,
            FiscalNumber: this.state.ObjData.fiscalNumber,
            Niss: this.state.ObjData.niss,
            IdDocumentType: this.state.ObjData.idDocumentType,
            DocumentNumber: this.state.ObjData.documentNumber,
            DocumentExpirationDate: DateUtc(this.state.ObjData.documentExpirationDate),
            IdLiteraryQualification: this.state.ObjData.idLiteraryQualification,
            IdStudyArea: this.state.ObjData.idStudyArea
        }

        
        var objSave = { PersonalData: objPersonalData, Workflow: this.state.ObjDataWorkflow, CandidateToken: this.state.token }
        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(objSave)
        }

        fetch("api/save", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json === true) {
                    window.location.href = window.location.protocol + '//' + window.location.host + '/Success?typeRequest=1'
                }
                else {
                    this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde", ToastTypes.Danger, false))
                }

            })
            .catch((error) => {
                console.log(error)
                this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde", ToastTypes.Danger, false))
            })
    }

    render() {

        return (

            <>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Label>Nome</Label>
                        <Input id="name" type="text" value={this.state.ObjData.name} invalid={this.state.ObjError.name} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="6" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Email</Label>
                        <Input id="email" value={this.state.ObjData.email} invalid={this.state.ObjError.email} type="text" className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                    <Col xs="6" sm="3" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Contacto</Label>
                        <Input id="phoneNumber" defaultValue={this.state.ObjData.phoneNumber} invalid={this.state.ObjError.phoneNumber} type="number" className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                    <Col xs="6" sm="3" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Data de Nascimento</Label>
                        <DatePicker showYearDropdown id="birthDate" onChange={(e) => this.handleChangeForm(e, 'birthDate')}
                            value={formatDate(this.state.ObjData.birthDate)} isClearable={true} showPopperArrow={false} dateFormat="yyyy-MM-dd" 
                            className={this.state.ObjError.birthDate === true ? "is-invalid form-control" : "form-control"}
                        >
                        </DatePicker>
                    </Col>
                </Row>
                <Row>

                    <Col xs="12" sm="12" md="9" lg="9" style={{ marginTop: 20 + 'px' }}>
                        <Label>Morada</Label>
                        <Input id="address" value={this.state.ObjData.address} invalid={this.state.ObjError.address} type="text" className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>

                    <Col xs="6" sm="3" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Cod.Postal</Label>
                        <Input id="postalCode" type="text" value={this.state.ObjData.postalCode !== undefined && this.state.ObjData.postalCode !== null ? this.state.ObjData.postalCode : ''} invalid={this.state.ObjError.postalCode} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                </Row>
                <Row>

                    <Col xs="12" sm="6" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Localidade</Label>
                        <Input id="postalLocation" type="text" value={this.state.ObjData.postalLocation} invalid={this.state.ObjError.postalLocation} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                </Row>

                <Row>

                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Nacionalidade</Label>
                        <Input type="select" id="idCitizenShip" value={this.state.ObjData.idCitizenship} invalid={this.state.ObjError.idCitizenship} onChange={(e) => this.handleChangeForm(e)}>
                            <option key="-1" value="-1"></option>
                            {this.state.nationalities.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>

                    <Col xs="6" sm="6" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>NIF</Label>
                        <Input id="fiscalNumber" type="number" defaultValue={this.state.ObjData.fiscalNumber} invalid={this.state.ObjError.fiscalNumber} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                    <Col xs="6" sm="6" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>NISS</Label>
                        <Input id="niss" type="number" defaultValue={this.state.ObjData.niss} invalid={this.state.ObjError.niss} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>

                </Row>

                <Row>

                    <Col xs="12" sm="12" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Tipo Documento</Label>
                        <Input type="select" id="idDocumentType" value={this.state.ObjData.idDocumentType} invalid={this.state.ObjError.idDocumentType} onChange={(e) => this.handleChangeForm(e)}>
                            <option key="-1" value="-1"></option>
                            {this.state.documentType.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>
                    <Col xs="6" sm="6" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Nº do Documento</Label>
                        <Input id="documentNumber" type="text" value={this.state.ObjData.documentNumber} invalid={this.state.ObjError.documentNumber} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                    <Col xs="6" sm="6" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Validade</Label>
                        <DatePicker showYearDropdown id="documentExpirationDate" onChange={(e) => this.handleChangeForm(e, 'documentExpirationDate')} value={formatDate(this.state.ObjData.documentExpirationDate)} isClearable={true} showPopperArrow={false} dateFormat="yyyy-MM-dd"
                            className={this.state.ObjError.documentExpirationDate === true ? "is-invalid form-control" : "form-control"}>
                        </DatePicker>
                    </Col>
                </Row>

                <Row>

                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Habilitações</Label>
                        <Input type="select" id="idLiteraryQualification" value={this.state.ObjData.idLiteraryQualification} invalid={this.state.ObjError.idLiteraryQualification} onChange={(e) => this.handleChangeForm(e)}>
                            <option key="-1" value="-1"></option>
                            {this.state.literaryAbilities.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Àrea de Estudo</Label>
                        <Input type="select" id="idStudyArea" value={this.state.ObjData.idStudyArea} invalid={this.state.ObjError.idStudyArea} onChange={(e) => this.handleChangeForm(e)}>
                            <option key="-1" value="-1"></option>
                            {this.state.studyAreas.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Se estás a estudar indica o ano de frequência</Label>
                        <Input type="select" id="AttendanceYear" value={this.state.ObjDataWorkflow.length > 0 ? this.state.ObjDataWorkflow[0].detail.attendanceYear : -1} onChange={(e) => this.handleChangeForm(e)}>
                            <option key="-1" value="-1"></option>
                            {this.state.attendanceYear.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>
                </Row>

                <Row style={{ marginTop: 20 + 'px' }}>
                    <Col xs="7" sm="9" md="9" lg="9">
                    </Col>
                    <Col xs="5" sm="3" md="3" lg="3" style={{ textAlign: 'right' }}>
                        <Button color="primary" onClick={(e) => this.handleSave(e)}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </Col>

                </Row>



            </>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.access_token, idCandidate: state.idCandidate })

export default connect(mapStateToProps)(InfoResume)