import React, { Component } from "react"
import { Container } from "reactstrap"
import { Loading } from "./global/Loading"
import { connect } from "react-redux"
import '../css/Layout.css'
import Alerts from "./global/Alerts"

class Layout extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showLeftMenu: true,
            pageTitle: this.props.PageTitle
        }

        this.handlePageTitle = this.handlePageTitle.bind(this)

        //console.log('Layout')
        //console.log(this.props.handlePageTitle)
        
    }

    handlePageTitle(title) {
        
        this.setState({ pageTitle: title })
    }

    render() {

        return (
            <>
                <Loading show={this.props.loading} />
                <Alerts attr={this.props.alert} />
                <div id="background"></div>
                <div style={{ maxWidth: 1024 + "px", margin: "auto" }}>
                    <header className="header">
                        <div className="top-left">
                            <div className="navbar-brand">
                                <img src={`${process.env.PUBLIC_URL}/images/McdLogo.png`} alt="Logo" width="40" height="40" />
                                <span className="header-title">{this.state.pageTitle}</span>
                            </div>
                        </div>
                        <div className="top-right">
                            <div style={{ float: 'right', fontSize: 15 + 'px' }}>
                            </div>
                        </div>
                    </header>
                    <Container className="content">
                            <div className="card mobile">
                                <div className="card-body mobile">
                                    {this.props.children}
                                </div>
                            </div>
                    </Container>
                    <div className="clearfix"></div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        alert: state.alert,
        path: state.path
    };
}

export default connect(mapStateToProps)(Layout)
