import React from "react"
import { Button, Row, Col } from 'reactstrap'
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { Toast, ToastTypes, GetParameter} from "./global/Utils"

class Disclaimer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            approved: false,
            token: GetParameter("token"),
        }
    }

    handleChangeForm() {
        this.setState({ approved: !this.state.approved })
    }

    handleSave() {

        if (this.state.approved === false) {
            this.props.dispatch(Toast("Por favor aceite os termos e condições", ToastTypes.Danger, false))
            return
        }

        if (this.state.token === undefined || this.state.token === null || this.state.token === '') {
            this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde", ToastTypes.Danger, false))
            return
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("api/disclaimer/" + this.state.token, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                window.location.href = window.location.protocol + '//' + window.location.host + '/' + json + '?token=' + this.state.token

            })
            .catch((error) => {
                console.log(error)
                this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde", ToastTypes.Danger, false))
            })
    }

    render() {
        return (
            <div>
                <center>
                    <Row>
                        <Col xs="12">
                            <Row>
                                <Col>
                                    <b>Tratamento de Dados Pessoais no Âmbito do Recrutamento</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "justify", margin: 10 + 'px' }}>
                                    No contexto do processo de recrutamento a decorrer, para que possa passar à fase seguinte, necessitamos de outros dados seus, nomeadamente: Documento de Identificação e todos os dados contidos no mesmo; Nome completo; Morada Fiscal; Data de Nascimento; Naturalidade; Nacionalidade; Email; Nº de Telefone; Género; Estado Civil; Situação Fiscal, nomeadamente nº de Dependentes e nº de Titulares; Nº de Deficientes no agregado familiar e respetivo grau de deficiência; IBAN; Certificado de Habilitações; Área de estudo; Situação escolar atual; se já alguma vez trabalhou e se sim, se trabalhou em restauração e qual o tempo do contrato.<br /><br />
                                    A Sistemas McDonald’s Portugal, Lda., com sede em Lagoas Park, Edifício 7, piso 2, 2740-244 Porto Salvo, matriculada na Conservatória do Registo Comercial de Cascais, sob o número único 502167017 e com o capital social de 4.300.000 Euros (“McDonald’s”), enquanto entidade responsável pelo tratamento dos seus dados pessoais irá tratar os dados aqui solicitados no âmbito de diligências pré-contratuais, para efeitos do presente processo de seleção e recrutamento e contactos com os candidatos.<br /><br />
                                    Em caso de contratação, os dados recolhidos no âmbito do processo serão utilizados para a gestão de recursos humanos, para assegurar o cumprimento das obrigações legais e para a disponibilização, aos colaboradores, de meios necessários à prossecução da atividade profissional.<br /><br />
                                    Mediante o seu consentimento, a McDonald’s conservará os dados pessoais dos candidatos não selecionados, incluindo informação relativa ao motivo da não seleção, com a finalidade de aceder e utilizar estes dados em processos de recrutamento futuros que envolvam o candidato. O candidato não será impedido de avançar no presente processo de recrutamento se não der o consentimento para o tratamento destes dados pessoais.<br /><br />
                                    A McDonald’s poderá transmitir os seus dados a entidades subcontratadas para as finalidades acima identificadas. A McDonald’s poderá também comunicá-los a entidades terceiras quando considerem tais comunicações de dados como necessárias ou adequadas: (i) à luz da lei aplicável; (ii) no cumprimento de obrigações legais/ordens judiciais; ou (iii) para responder a solicitações de autoridades públicas ou governamentais.<br /><br />
                                    Os dados pessoais recolhidos no contexto do presente processo de recrutamento serão conservados pelo prazo máximo de 1 (um) ano após o fim do procedimento de seleção e recrutamento de pessoal, sem prejuízo de tal prazo se estender pelo tempo de duração de eventual processo judicial e até ao limite de 6 (seis) meses após o trânsito em julgado da respetiva sentença. Caso consinta na conservação dos seus dados pessoais para utilização em processos de recrutamento futuros, os dados pessoais de candidatos não selecionados serão conservados pelo prazo máximo de 3 (três) anos após o fim do procedimento de seleção e recrutamento de pessoal, sem prejuízo de tal prazo se estender pelo tempo de duração de eventual processo judicial e até ao limite de 6 (seis) meses após o trânsito em julgado da respetiva sentença.<br /><br />
                                    Nos termos da legislação aplicável, poderá solicitar, a todo o tempo, o acesso aos dados pessoais que lhe digam respeito, bem como a sua retificação, eliminação ou a limitação do seu tratamento, a portabilidade dos seus dados, ou opor-se ao seu tratamento, através do e-mail dpo.mcdonaldsportugal@pt.mcd.com ou mediante pedido escrito remetido por correio para DPO - Sistemas McDonald's Portugal, Lagoas Park, Edifício 7, piso 2, Porto Salvo, Oeiras. Quando o tratamento dos seus dados se baseie no consentimento, tem o direito de o retirar a qualquer momento, sem prejuízo do tratamento efetuado com base no consentimento previamente dado.<br /><br />
                                    Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, tem direito a apresentar uma reclamação à Comissão Nacional de Proteção de Dados, caso considere que os seus dados não estão a ser objeto de tratamento lícito por parte da McDonald’s, nos termos da legislação aplicável.<br /><br />
                                    Para mais informações sobre os termos do tratamento de dados pela McDonald's, por favor consulte a nossa Política de Privacidade em Avisos Legais e Privacidade | McDonald's Portugal (mcdonalds.pt).<br /><br />
                                    Se desejar consentir no tratamento dos seus dados pessoais para efeitos de utilização em processos de recrutamento futuros da McDonald’s, nos termos referidos acima deverá clicar aqui.<br /><br />

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginTop : 40 +'px' }}>
                        <Col>
                            <label for="scales">Aceito os termos e condições:</label>
                            <input style={{ marginLeft: 10 + 'px' }} type="checkbox" onClick={(e) => this.handleChangeForm()} checked={this.state.approved} id="scales" name="scales" />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 40 + 'px' }}>
                        <Col>
                            <Button color="primary" onClick={(e) => this.handleSave(e)}><FontAwesomeIcon icon={faSave} /> Aceitar</Button>
                        </Col>
                    </Row>
                </center>
            </div>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.access_token })

export default connect(mapStateToProps)(Disclaimer)