import React from 'react'
import { Button, Row, Col, Input, Label } from 'reactstrap';
import { connect } from "react-redux"
import { Toast, ToastTypes, Loading, formatDate, IsValidNIF, IsNumeric, maskPostalCode, CheckPostalCode, GetParameter,DateUtc } from "./global/Utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave, faPlusCircle, faTimesCircle, faFileWord, faFilePdf, faFileImage } from "@fortawesome/free-solid-svg-icons"
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"


class InfoDetail extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            token: '',
            literaryAbilities: [],
            studyAreas: [],
            nationalities: [],
            documentType: [],
            attendanceYear: [],
            civilstates: [],
            filesType: [],
            ObjData: {},
            ObjDataWorkflow: [],
            ObjError: {
                name: false, email: false, phoneNumber: false, birthDate: false, address: false,
                postalCode: false, postalLocation: false, idCitizenship: false, fiscalNumber: false, niss: false,
                idDocumentType: false, documentNumber: false, documentExpirationDate: false, idLiteraryQualification: false,
                idStudyArea: false, idCivilState: false, gender: false, bornIn: false, firstJob: false, workedInRestaurant: false, beEfective: false, contractLength: false,
                filetype: false, emergencyContact: false
            },
            totalDependents: 0,
            idDependent: 0,
            Dependents: [],
            DependentsError: [],
            showCombos: 'inline',
            ObjFiles: [],
            imagePath: '/images/Plus.png',
            newButtonFileDisabled: true
        }
    }

    componentDidMount() {

        if (GetParameter("token") === undefined)
            return;
        else
            this.setState({ token: GetParameter("token") })

        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("api/literaryabilities", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ literaryAbilities: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos das habilitações literárias", ToastTypes.Danger, false)))

        fetch("api/studyareas", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ studyAreas: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos das àreas de estudo", ToastTypes.Danger, false)))

        fetch("api/nationalities", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ nationalities: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos das nacionalidades", ToastTypes.Danger, false)))

        fetch("api/documentType", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ documentType: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os tipos de documentos", ToastTypes.Danger, false)))

        fetch("api/attendanceyear", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ attendanceYear: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os anos de frequência", ToastTypes.Danger, false)))

        fetch("api/candidatepersonaldata?idCandidate=" + parseInt(this.props.idCandidate), requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json.personalData.postalCode !== null && json.personalData.postalCode !== undefined)
                    json.personalData.postalCode = maskPostalCode(json.personalData.postalCode)

                this.setState({ ObjData: json.personalData, ObjDataWorkflow: json.workflow })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter a informação relativa ao candidato.", ToastTypes.Danger, false)))

        fetch("api/candidatepersonaldatadependents?idCandidate=" + parseInt(this.props.idCandidate), requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                var arrDependents = []
                var arrDependentsError = []
                var maxIdDependent = 0

                json.forEach(a => {
                    arrDependents.push({ IdDependent: a.idDependent, IdCandidate: parseInt(a.idCandidate), Name: a.name, BirthDate: a.birthDate, Gender: a.gender })
                    arrDependentsError.push({ IdDependent: a.idDependent, IdCandidate: parseInt(a.idCandidate), Name: false, BirthDate: false, Gender: false })
                    maxIdDependent = a.idDependent

                })
                this.setState({ Dependents: arrDependents, DependentsError: arrDependentsError, idDependent: maxIdDependent })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter a informação relativa aos dependentes.", ToastTypes.Danger, false)))

        fetch("api/civilstate", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ civilstates: json })
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos dos estados civis", ToastTypes.Danger, false)))


        fetch("api/filestype", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                var obj = []
                json.forEach(a => {
                    obj.push({ IdFileType: a.key, FileTypeDescription: a.value, base64File: '', fileName: '', idFile: -1, extention: '' })
                })
                this.setState({ ObjFiles: obj })

            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos dos tipos de ficheiros", ToastTypes.Danger, false)))

        this.props.dispatch(Loading(false))

    }

    handleChangeForm(e, DateId, IsDetailData) {
        let dataValue = DateId !== undefined && DateId !== null ? e : e.target.value.toUpperCase()
        var id = DateId !== undefined && DateId !== null ? DateId : e.target.id
        var showCombo = 'inline'

        if (IsDetailData === true) {

            if (id === 'firstJob') {
                if (parseInt(dataValue) === 1)
                    showCombo = 'none'
            }

            var objWorkflow = []
            var objDetail = {};
            this.state.ObjDataWorkflow.forEach(a => {
                var aux = a
                objDetail = a.detail;
                objDetail = { ...objDetail, [id]: parseInt(dataValue) }
                aux = { ...aux, detail: objDetail }
                objWorkflow.push(aux)
            })
            this.setState({ showCombos: showCombo, ObjDataWorkflow: objWorkflow, ObjError: { ...this.state.ObjError, [id]: dataValue === '' || dataValue === -1 || dataValue === null ? true : false } })
        }
        else {

            if (id === 'idFilesType') {

                var arrFileType = []

                this.state.filesType.forEach(a => {
                    arrFileType.push({ key: a.key, value: a.value, selected: parseInt(a.key) === parseInt(dataValue) ? true : false })
                })
                this.setState({ newButtonFileDisabled: false, filesType: arrFileType, ObjError: { ...this.state.ObjError, filetype: dataValue === -1 ? true : false } })
            }
            else if (id === 'postalCode') {
                dataValue = maskPostalCode(dataValue)
                this.setState({ ObjData: { ...this.state.ObjData, [id]: dataValue }, ObjError: { ...this.state.ObjError, [id]: CheckPostalCode(dataValue, true) === false ? true : false } })
            }
            else if (id === 'niss') {
                this.setState({ ObjData: { ...this.state.ObjData, [id]: dataValue }, ObjError: { ...this.state.ObjError, [id]: dataValue.length !== 11 ? true : false } })
            }
            else {

                //if (id === 'gender') {
                //    dataValue = dataValue !== -1 ? dataValue.toLowerCase()  : null
                //    //dataValue = dataValue !== -1 ? dataValue === 'true' ? true : false : null
                //}

                this.setState({ ObjData: { ...this.state.ObjData, [id]: dataValue }, ObjError: { ...this.state.ObjError, [id]: dataValue === '' || dataValue === -1 ? true : false } })
            }
        }
    }

    handleAddDependent() {

        var idDependent = this.state.idDependent === undefined ? 0 : this.state.idDependent + 1
        var arrDependents = [...this.state.Dependents]
        var arrDependentsError = [...this.state.DependentsError]
        arrDependents.push({ IdDependent: idDependent, IdCandidate: parseInt(this.props.idCandidate), Name: '', BirthDate: '', Gender: -1 })
        arrDependentsError.push({ IdDependent: idDependent, Name: false, BirthDate: false, Gender: false })
        this.setState({ Dependents: arrDependents, idDependent: idDependent, DependentsError: arrDependentsError })
    }

    handleRemoveDependent(idDependent) {
        var arrDependents = [...this.state.Dependents]
        var arrDependentsError = [...this.state.DependentsError]
        arrDependents = arrDependents.filter(a => parseInt(a.IdDependent) !== parseInt(idDependent))
        arrDependentsError = arrDependentsError.filter(a => parseInt(a.IdDependent) !== parseInt(idDependent))
        this.setState({ Dependents: arrDependents, DependentsError: arrDependentsError })
    }

    handleChangeDependent(e, idDependent, DateId) {

        let dataValue = DateId !== undefined && DateId !== null ? e : e.target.value.toUpperCase()
        var id = DateId !== undefined && DateId !== null ? DateId : e.target.id
        var erroAux = false

        if (id.startsWith('DependentName')) {
            id = 'Name'
            erroAux = dataValue === '' ? true : false
        }
        else if (id.startsWith('DependentBirthDate')) {
            id = 'BirthDate'
            erroAux = dataValue === '' ? true : false
        }
        else if (id.startsWith('lstDependentGender_')) {
            id = 'Gender'
            erroAux = parseInt(dataValue) === -1 ? true : false
        }

        var dependent = this.state.Dependents.find(a => a.IdDependent === idDependent)
        var final = [...this.state.Dependents.filter(a => a.IdDependent !== idDependent)]
        dependent[id] = dataValue
        final.push(dependent)

        final = final.sort((a, b) => (a.IdDependent > b.IdDependent) ? 1 : ((b.IdDependent > a.IdDependent) ? -1 : 0))

        //Error 
        var dependentError = this.state.DependentsError.find(a => a.IdDependent === idDependent)
        var finalError = [...this.state.DependentsError.filter(a => a.IdDependent !== idDependent)]
        dependentError[id] = erroAux
        finalError.push(dependentError)
        this.setState({ Dependents: final, DependentsError: finalError })
    }

    handleSave() {

        if (this.state.token === undefined || this.state.token === null || this.state.token === '') {
            this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde", ToastTypes.Danger, false))
            return
        }



        var error = this.state.ObjError

        error.name = this.state.ObjData.name === '' || this.state.ObjData.name === null
        error.email = this.state.ObjData.email === '' || this.state.ObjData.email === null
        error.phoneNumber = this.state.ObjData.phoneNumber === '' || this.state.ObjData.phoneNumber === null
        error.emergencyContact = this.state.ObjData.emergencyContact === '' || this.state.ObjData.emergencyContact === null
        error.birthDate = this.state.ObjData.birthDate === '' || this.state.ObjData.birthDate === null
        error.address = this.state.ObjData.address === '' || this.state.ObjData.address === null
        error.postalCode = CheckPostalCode(this.state.ObjData.postalCode, true) === false ? true : false
        error.postalLocation = this.state.ObjData.postalLocation === '' || this.state.ObjData.postalLocation === null
        error.idCitizenship = this.state.ObjData.idCitizenship === -1 || this.state.ObjData.idCitizenship === null
        error.fiscalNumber = this.state.ObjData.fiscalNumber === '' || this.state.ObjData.fiscalNumber === null || !IsValidNIF(this.state.ObjData.fiscalNumber)
        error.niss = this.state.ObjData.niss === '' || this.state.ObjData.niss === null || !IsNumeric(this.state.ObjData.niss) || this.state.ObjData.niss.length !== 11
        error.idDocumentType = this.state.ObjData.idDocumentType === -1 || this.state.ObjData.idDocumentType === null
        error.documentNumber = this.state.ObjData.documentNumber === '' || this.state.ObjData.documentNumber === null
        error.documentExpirationDate = this.state.ObjData.documentExpirationDate === '' || this.state.ObjData.documentExpirationDate === null
        error.idLiteraryQualification = this.state.ObjData.idLiteraryQualification === -1 || this.state.ObjData.idLiteraryQualification === null
        error.idStudyArea = this.state.ObjData.idStudyArea === -1 || this.state.ObjData.idStudyArea === null
        error.idCivilState = this.state.ObjData.idCivilState === -1 || this.state.ObjData.idCivilState === null
        error.gender = parseInt(this.state.ObjData.gender) === -1 || this.state.ObjData.gender === null
        error.bornIn = this.state.ObjData.bornIn === '' || this.state.ObjData.bornIn === null
        error.firstJob = this.state.ObjDataWorkflow.length > 0 && (this.state.ObjDataWorkflow[0].detail.firstJob === null || this.state.ObjDataWorkflow[0].detail.firstJob === -1)

        if (this.state.ObjDataWorkflow.length > 0 && this.state.ObjDataWorkflow[0].detail.firstJob === 0) {
            error.contractLength = this.state.ObjDataWorkflow.length > 0 && (this.state.ObjDataWorkflow[0].detail.contractLength === null || this.state.ObjDataWorkflow[0].detail.contractLength === -1)
            error.workedInRestaurant = this.state.ObjDataWorkflow.length > 0 && (this.state.ObjDataWorkflow[0].detail.workedInRestaurant === null || this.state.ObjDataWorkflow[0].detail.workedInRestaurant === -1)
            error.beEfective = this.state.ObjDataWorkflow.length > 0 && (this.state.ObjDataWorkflow[0].detail.beEfective === null || this.state.ObjDataWorkflow[0].detail.beEfective === -1)
        }
        else {
            error.contractLength = false
            error.workedInRestaurant = false
            error.beEfective = false
        }

        //Candidates Dependents
        var errorDependents = []
        var hasErrorDependent = false
        this.state.Dependents.forEach(a => {

            var obj = { IdDependent: a.IdDependent, Name: false, BirthDate: false, Gender: false }

            if (a.Name === '') {
                obj.Name = true
                hasErrorDependent = true
            }
            if (a.BirthDate === '') {
                obj.BirthDate = true
                hasErrorDependent = true
            }
            if (parseInt(a.Gender) === -1) {
                obj.Gender = true
                hasErrorDependent = true
            }

            errorDependents.push(obj)
        })

        this.setState({ ObjError: error, DependentsError: errorDependents })

        var hasError = false
        for (var propt in error) {
            if (error[propt])
                hasError = true
        }

        if (hasError || hasErrorDependent) {
            this.props.dispatch(Toast("Por favor preencha todos os campos", ToastTypes.Warning, false))
            return
        }
            

        var ArrDocuments = []

        this.state.ObjFiles.forEach(a => {
            if (a.base64File !== '')
                ArrDocuments.push({ Base64File: a.base64File, FileName: a.fileName, FileExtension: a.extention, IdFileType: a.IdFileType })
        })

        var objPersonalData = {
            IdCandidate: parseInt(this.props.idCandidate),
            IdWorkflow: -1,
            Name: this.state.ObjData.name,
            PhoneNumber: this.state.ObjData.phoneNumber,
            EmergencyContact: this.state.ObjData.emergencyContact,
            Email: this.state.ObjData.email,
            BirthDate: DateUtc(this.state.ObjData.birthDate),
            Address: this.state.ObjData.address,
            PostalCode: this.state.ObjData.postalCode.toString().replace('-', ''),
            PostalLocation: this.state.ObjData.postalLocation,
            IdCitizenship: this.state.ObjData.idCitizenship,
            FiscalNumber: this.state.ObjData.fiscalNumber,
            Niss: this.state.ObjData.niss,
            IdDocumentType: this.state.ObjData.idDocumentType,
            DocumentNumber: this.state.ObjData.documentNumber,
            DocumentExpirationDate: DateUtc(this.state.ObjData.documentExpirationDate),
            IdLiteraryQualification: this.state.ObjData.idLiteraryQualification,
            IdStudyArea: this.state.ObjData.idStudyArea,
            Archive: this.state.ObjData.archive,
            Gender: parseInt(this.state.ObjData.gender) === 1,
            BornIn: this.state.ObjData.bornIn,
            IdCivilState: this.state.ObjData.idCivilState,
            SpouseName: this.state.ObjData.spouseName,
            SpouseBirthDate: DateUtc(this.state.ObjData.spouseBirthDate),
            CandidatesDependent: this.state.Dependents,
            Documents: ArrDocuments
        }

        var objSave = { PersonalData: objPersonalData, Workflow: this.state.ObjDataWorkflow, CandidateToken: this.state.token }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(objSave)
        }

        fetch("api/save", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                if (json === true) {
                    window.location.href = window.location.protocol + '//' + window.location.host + '/Success?typeRequest=1'
                }
                else {
                    this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde", ToastTypes.Danger, false))
                }

            })
            .catch((error) => {
                console.log(error)
                this.props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde", ToastTypes.Danger, false))
            })


    }

    GetFileImage(extentiton) {
        if (extentiton === 'doc' || extentiton === 'docx')
            return faFileWord
        else if (extentiton === 'pdf')
            return faFilePdf
        else
            return faFileImage
    }

    GetExtention(type) {
        var extention = ''
        if (type === 'application/msword' || 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            extention = 'doc'
        else if (type === 'application/pdf')
            extention = 'pdf'
        else if (type === 'image/jpeg')
            extention = 'jpeg'
        else if (type === 'image/bmp')
            extention = 'bmp'
        else if (type === 'image/png')
            extention = 'png'

        return extention
    }

    handleChangeFile = (e, idFileType) => {
        const file = e.target.files[0]

        if (file.type !== "application/msword" && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type !== "application/pdf" && file.type !== 'image/jpeg' && file.type !== 'image/bmp' && file.type !== 'image/png') {
            this.props.dispatch(Toast("Apenas são permitidos ficheiros do tipo : Word, PDF, JPG, BMP,PNG!", ToastTypes.Warning, false))
            return
        }
        if (file.size > 3145728) { // 3MB
            this.props.dispatch(Toast("O tamanho máximo do ficheiro são 3MB!", ToastTypes.Warning, false))
            return
        }

        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = (e) => {
            var base64File = fileReader.result.split(",")[1]
            var fileName = file.name
            var ArrObjFiles = []
            var objFile = {}
            this.state.ObjFiles.forEach(a => {
                if (parseInt(a.IdFileType) === parseInt(idFileType))
                    objFile = { ...a, base64File: base64File, fileName: fileName, extention: fileName.substring(fileName.lastIndexOf('.') + 1) }
                else
                    objFile = { ...a, base64File: a.base64File, fileName: a.fileName, extention: a.extention }
                ArrObjFiles.push(objFile)
            })
            this.setState({
                ObjFiles: ArrObjFiles
            })
        }
    }

    deleteFile(e, idFile) {
        e.preventDefault();
        var arrFiles = []
        this.state.ObjFiles.forEach(x => {
            if (x.IdFileType === idFile)
                arrFiles.push({ IdFileType: x.IdFileType, FileTypeDescription: x.FileTypeDescription, base64File: '', fileName: '', idFile: x.idFile, extention: '' })
            else
                arrFiles.push({ IdFileType: x.IdFileType, FileTypeDescription: x.FileTypeDescription, base64File: x.base64File, fileName: x.fileName, idFile: x.idFile, extention: x.extention })
        })
        this.setState({
            ObjFiles: arrFiles
        })
    }

    render() {

        return (

            <>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <Label>Nome Completo</Label>
                        <Input id="name" type="text" value={this.state.ObjData.name} invalid={this.state.ObjError.name} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="6" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Email</Label>
                        <Input id="email" value={this.state.ObjData.email} invalid={this.state.ObjError.email} type="text" className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                    <Col xs="6" sm="3" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Data de Nascimento</Label>
                        <DatePicker showYearDropdown id="birthDate"
                            onChange={(e) => this.handleChangeForm(e, 'birthDate')} value={formatDate(this.state.ObjData.birthDate)} isClearable={true} showPopperArrow={false} dateFormat="yyyy-MM-dd"
                            className={this.state.ObjError.birthDate === true ? "is-invalid form-control" : "form-control"}
                        >
                        </DatePicker>
                    </Col>
                </Row>

                <Row>
                    <Col xs="6" sm="3" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Contacto</Label>
                        <Input id="phoneNumber" defaultValue={this.state.ObjData.phoneNumber} invalid={this.state.ObjError.phoneNumber} type="number" className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                    <Col xs="6" sm="3" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Contacto de Emergência</Label>
                        <Input id="emergencyContact" defaultValue={this.state.ObjData.emergencyContact} invalid={this.state.ObjError.emergencyContact} type="number" className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12" md="9" lg="9" style={{ marginTop: 20 + 'px' }}>
                        <Label>Morada Fiscal</Label>
                        <Input id="address" value={this.state.ObjData.address} invalid={this.state.ObjError.address} type="text" className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                    <Col xs="6" sm="3" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Cod. Postal</Label>
                        <Input id="postalCode" type="text" value={this.state.ObjData.postalCode !== undefined && this.state.ObjData.postalCode !== null ? this.state.ObjData.postalCode : ''} invalid={this.state.ObjError.postalCode} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="6" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Cidade onde nasceste</Label>
                        <Input id="postalLocation" type="text" value={this.state.ObjData.postalLocation} invalid={this.state.ObjError.postalLocation} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Nacionalidade</Label>
                        <Input type="select" id="idCitizenship" value={this.state.ObjData.idCitizenship} invalid={this.state.ObjError.idCitizenship} onChange={(e) => this.handleChangeForm(e)}>
                            <option key="-1" value="-1"></option>
                            {this.state.nationalities.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>

                    <Col xs="6" sm="6" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>NIF</Label>
                        <Input id="fiscalNumber" type="number" defaultValue={this.state.ObjData.fiscalNumber} invalid={this.state.ObjError.fiscalNumber} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                    <Col xs="6" sm="6" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>NISS</Label>
                        <Input id="niss" type="number" defaultValue={this.state.ObjData.niss} invalid={this.state.ObjError.niss} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>

                </Row>

                <Row>

                    <Col xs="12" sm="12" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Tipo Documento</Label>
                        <Input type="select" id="idDocumentType" value={this.state.ObjData.idDocumentType} invalid={this.state.ObjError.idDocumentType} onChange={(e) => this.handleChangeForm(e)}>
                            <option key="-1" value="-1"></option>
                            {this.state.documentType.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>
                    <Col xs="6" sm="6" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Nº do Documento</Label>
                        <Input id="documentNumber" type="text" value={this.state.ObjData.documentNumber} invalid={this.state.ObjError.documentNumber} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                    <Col xs="6" sm="6" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Validade</Label>
                        <DatePicker showYearDropdown id="documentExpirationDate" onChange={(e) => this.handleChangeForm(e, 'documentExpirationDate')}
                            value={formatDate(this.state.ObjData.documentExpirationDate)} isClearable={true} showPopperArrow={false} dateFormat="yyyy-MM-dd"
                            className={this.state.ObjError.documentExpirationDate === true ? "is-invalid form-control" : "form-control"}
                        >
                        </DatePicker>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Local de Emissão</Label>
                        <Input id="archive" type="text" value={this.state.ObjData.archive} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>
                    <Col xs="12" sm="12" md="3" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Género</Label>
                        <Input type="select" id="gender" value={this.state.ObjData.gender === null ? -1 : this.state.ObjData.gender} invalid={this.state.ObjError.gender} onChange={(e) => this.handleChangeForm(e)}>
                            <option value={-1}></option>
                            <option value={1}>Masculino</option>
                            <option value={0}>Feminino</option>
                        </Input>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Habilitações</Label>
                        <Input type="select" id="idLiteraryQualification" value={this.state.ObjData.idLiteraryQualification} invalid={this.state.ObjError.idLiteraryQualification} onChange={(e) => this.handleChangeForm(e)}>
                            <option key="-1" value="-1"></option>
                            {this.state.literaryAbilities.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Área de Estudo</Label>
                        <Input type="select" id="idStudyArea" value={this.state.ObjData.idStudyArea} invalid={this.state.ObjError.idStudyArea} onChange={(e) => this.handleChangeForm(e)}>
                            <option key="-1" value="-1"></option>
                            {this.state.studyAreas.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Se estás a estudar, indica o ano de frequência</Label>
                        <Input type="select" id="attendanceYear" value={this.state.ObjDataWorkflow.length > 0 && this.state.ObjDataWorkflow[0].detail.attendanceYear ? this.state.ObjDataWorkflow[0].detail.attendanceYear : -1} onChange={(e) => this.handleChangeForm(e, null, true)}>
                            <option key="-1" value="-1"></option>
                            {this.state.attendanceYear.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12" md="6" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>1º Emprego?</Label>
                        <Input type="select" id="firstJob" invalid={this.state.ObjError.firstJob} value={this.state.ObjDataWorkflow.length > 0 && this.state.ObjDataWorkflow[0].detail.firstJob !== null ? this.state.ObjDataWorkflow[0].detail.firstJob : -1} onChange={(e) => this.handleChangeForm(e, null, true)}>
                            <option value="-1"></option>
                            <option value="1">Sim</option>
                            <option value="0">Não</option>
                        </Input>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="3" style={{ marginTop: 20 + 'px', display: this.state.showCombos }}>
                        <Label>Já trabalhaste em restauração?</Label>
                        <Input type="select" id="workedInRestaurant" invalid={this.state.ObjError.workedInRestaurant} value={this.state.ObjDataWorkflow.length > 0 && this.state.ObjDataWorkflow[0].detail.workedInRestaurant !== null ? this.state.ObjDataWorkflow[0].detail.workedInRestaurant : -1} onChange={(e) => this.handleChangeForm(e, null, true)}>
                            <option value="-1"></option>
                            <option value="1">Sim</option>
                            <option value="0">Não</option>
                        </Input>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="3" style={{ marginTop: 20 + 'px', display: this.state.showCombos }}>
                        <Label>Já foste efetivo em Portugal?</Label>
                        <Input type="select" id="beEfective" invalid={this.state.ObjError.beEfective} value={this.state.ObjDataWorkflow.length > 0 && this.state.ObjDataWorkflow[0].detail.beEfective !== null ? this.state.ObjDataWorkflow[0].detail.beEfective : -1} onChange={(e) => this.handleChangeForm(e, null, true)}>
                            <option value="-1"></option>
                            <option value="1">Sim</option>
                            <option value="0">Não</option>
                        </Input>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="3" style={{ marginTop: 20 + 'px', display: this.state.showCombos }}>
                        <Label>Duração do Contrato</Label>
                        <Input type="select" id="contractLength" invalid={this.state.ObjError.contractLength} value={this.state.ObjDataWorkflow.length > 0 && this.state.ObjDataWorkflow[0].detail.contractLength !== null ? this.state.ObjDataWorkflow[0].detail.contractLength : -1} onChange={(e) => this.handleChangeForm(e, null, true)}>
                            <option value="-1"></option>
                            <option value="0"> &lt;90 Dias</option>
                            <option value="1">=90 Dias</option>
                            <option value="2">&gt;90 Dias</option>
                        </Input>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Naturalidade</Label>
                        <Input id="bornIn" type="text" value={this.state.ObjData.bornIn} invalid={this.state.ObjError.bornIn} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Estado Civil</Label>
                        <Input type="select" id="idCivilState" value={this.state.ObjData.idCivilState} invalid={this.state.ObjError.idCivilState} onChange={(e) => this.handleChangeForm(e)}>
                            <option key="-1" value="-1"></option>
                            {this.state.civilstates.map(a => <option key={a.key} value={a.key}>{a.value}</option>)}
                        </Input>
                    </Col>

                </Row>

                <Row>
                    <Col xs="12" sm="12" md="6" lg="6" style={{ marginTop: 20 + 'px' }}>
                        <Label>Nome do Cônjugue</Label>
                        <Input id="spouseName" type="text" value={this.state.ObjData.spouseName} className="form-control" autoComplete="off"
                            onChange={(e) => this.handleChangeForm(e)} />
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="3" style={{ marginTop: 20 + 'px' }}>
                        <Label>Data de Nascimento Cônjugue</Label>
                        <DatePicker showYearDropdown id="spouseBirthDate" onChange={(e) => this.handleChangeForm(e, 'spouseBirthDate')} value={this.state.ObjData.spouseBirthDate !== null ? formatDate(this.state.ObjData.spouseBirthDate) : null} isClearable={true} showPopperArrow={false} dateFormat="yyyy-MM-dd" className="form-control">
                        </DatePicker>
                    </Col>
                </Row>

                <Row>
                    <Col xs="6" sm="6" md="3" lg="2" style={{ marginTop: 20 + 'px' }}>
                        <Label>Dependentes</Label>
                    </Col>
                </Row>

                <Row>
                    <Col xs="6" sm="6" md="3" lg="2">
                        <FontAwesomeIcon id="btnAddDependent" title="Adicionar Dependente" onClick={(e) => this.handleAddDependent()} style={{ marginLeft: 2 + 'px', verticalAlign: "baseline", color: "#1b6ec2", height: 35 + 'px', width: 22 + 'px', paddingTop: 0 + 'px', cursor: 'pointer' }} color="primary" icon={faPlusCircle} />
                    </Col>
                </Row>

                {
                    this.state.Dependents.map((a, index) => {
                        return (
                            <div key={a.IdDependent} style={{ height: 100 + '%', width: 100 + '%', border: 1 + 'px', marginTop: 20 + 'px', borderStyle: 'solid', borderColor: '#ced4da', borderRadius: 0.25 + 'rem' }}>
                                <FontAwesomeIcon onClick={(e) => this.handleRemoveDependent(a.IdDependent)} key={"IconDelete_" + a.IdDependent} className="boxclose" aria-hidden={true} icon={faTimesCircle} />

                                <Row style={{ marginBottom: 20 + 'px' }}>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                        <Label key={"lblDependentName_" + a.IdDependent} >Nome do Dependente</Label>
                                        <Input id={"DependentName_" + a.IdDependent} key={"DependentName_" + a.IdDependent} invalid={this.state.DependentsError.find(b => b.IdDependent === a.IdDependent).Name} value={this.state.Dependents.find(b => b.IdDependent === a.IdDependent).Name} type="text" className="form-control" autoComplete="off"
                                            onChange={(e) => this.handleChangeDependent(e, a.IdDependent)} />
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="3">
                                        <Label key={"lblDependentBirthDate_" + a.IdDependent}>Data de Nascimento</Label>
                                        <DatePicker showYearDropdown id={"DependentBirthDate_" + a.IdDependent} className={this.state.DependentsError.find(b => b.IdDependent === a.IdDependent).BirthDate === true ? "is-invalid form-control" : "form-control"} value={this.state.Dependents !== undefined && this.state.Dependents.length > 0 && this.state.Dependents.find(b => b.IdDependent === a.IdDependent).BirthDate !== '' ? formatDate(this.state.Dependents.find(b => b.IdDependent === a.IdDependent).BirthDate) : null} key={"DependentBirthDate_" + a.IdDependent} onChange={(e) => this.handleChangeDependent(e, a.IdDependent, "DependentBirthDate_" + a.IdDependent)} isClearable={true} showPopperArrow={false} dateFormat="yyyy-MM-dd">
                                        </DatePicker>
                                    </Col>

                                    <Col xs="12" sm="12" md="3" lg="3">
                                        <Label key={"lblDependentGender_" + a.IdDependent}>Género</Label>
                                        <Input type="select" key={"lstDependentGender_" + a.IdDependent} invalid={this.state.DependentsError.find(b => b.IdDependent === a.IdDependent).Gender} value={this.state.Dependents.find(b => b.IdDependent === a.IdDependent).Gender} id={"lstDependentGender_" + a.IdDependent} onChange={(e) => this.handleChangeDependent(e, a.IdDependent)}>
                                            <option value="-1"></option>
                                            <option value="1">Masculino</option>
                                            <option value="0">Feminino</option>
                                        </Input>
                                    </Col>

                                </Row>
                            </div>
                        )
                    })
                }
                <Row style={{ marginTop: 20 + 'px' }}>
                    <Col xs="12" sm="12" md="6" lg="3">
                        <Label><b>Ficheiros</b></Label>
                    </Col>
                </Row>

                <Row style={{ marginTop: 20 + 'px' }}>
                    <Col xs="12" sm="12" md="12" lg="12">

                        <table className="table table-borderless">
                            <thead className="grid-header">
                                <tr>
                                    <th className="align-self-left" style={{ width: 90 + '%' }}><span className="d-sm-block">Tipo</span></th>
                                    <th className="align-self-center"><span className="d-sm-block">Ficheiro</span></th>

                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.ObjFiles.map(result => {
                                        return (
                                            <tr key={"tr_" + result.IdFileType} className="grid-rows" style={{ minHeight: 35 + 'px', height: 35 + 'px', borderTopWidth: 1 + 'px', borderTopStyle: "solid", borderTopColor: "gainsboro" }}>
                                                <td key={"tdFileType_" + result.IdFileType} className="align-self-left" style={{ verticalAlign: 'middle' }}>{result.FileTypeDescription}</td>
                                                <td key={"tdFile_" + result.IdFileType} className="align-self-left" style={{ padding: 0 }}>
                                                    {result.fileName === '' ?
                                                        <Col id={"dvFileNew" + result.IdFileType} key={"dvFileNew" + result.IdFileType} xs="2" sm="2" md="2" lg="2" className="image-upload">
                                                            <label id={"lblFile" + result.IdFileType} htmlFor={"newFile" + result.IdFileType} key={"lblFile" + result.IdFileType} style={{ cursor: "pointer", marginTop: 5 + 'px' }}>
                                                                <img src={this.state.imagePath} key={"img" + result.IdFileType} alt="Adicionar Ficheiro" style={{ border: "1px solid #ced4da", height: 60 + 'px', width: 60 + 'px', borderRadius : 10 + 'px' }} />
                                                            </label>
                                                            <input id={"newFile" + result.IdFileType} type="file" key={"newFile" + result.IdFileType} onChange={(e) => this.handleChangeFile(e, result.IdFileType)} />
                                                        </Col>
                                                        :

                                                        <Col key={"dvFileExists" + result.IdFileType} xs="2" sm="2" md="2" lg="2">
                                                            <div id={"dvFileExists" + result.IdFileType} style={{ marginTop: 10 + 'px', height: 60 + 'px', width: 60 + 'px', border: 1 + 'px', borderStyle: 'solid', borderColor: '#ced4da', borderRadius: 10 + 'px', marginBottom: 7 + 'px' }}>
                                                                <a href="#top" className="boxclose2" onClick={(e) => this.deleteFile(e, result.IdFileType)} key={"closeBox" + result.IdFileType} id={"closeBox" + result.IdFileType}>
                                                                    <FontAwesomeIcon icon={faTimesCircle} /></a>
                                                                <FontAwesomeIcon title={result.fileName} key={"fileDownload" + result.IdFileType} id={"fileDownload" + result.IdFileType}
                                                                    style={{ height: 33 + 'px', width: 51 + 'px', color: '#1b6ec2', paddingTop: 3 + 'px', paddingLeft: 11 + 'px', marginTop: 10 + 'px' }} icon={this.GetFileImage(result.extention)} />
                                                            </div>
                                                        </Col>

                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })

                                }

                            </tbody>
                        </table>

                    </Col>
                </Row>


                <Row style={{ marginTop: 20 + 'px' }}>
                    <Col xs="7" sm="9" md="9" lg="9">
                    </Col>
                    <Col xs="5" sm="3" md="3" lg="3" style={{ textAlign: 'right' }}>
                        <Button color="primary" onClick={(e) => this.handleSave(e)}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </Col>
                </Row>

            </>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.access_token, idCandidate: state.idCandidate })

export default connect(mapStateToProps)(InfoDetail)