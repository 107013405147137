import React, { useState, useEffect } from "react"
import { Button, Row, Col, Label, ButtonGroup, ButtonToolbar } from 'reactstrap'
import { Toast, ToastTypes, Loading, GetParameter } from "./global/Utils"
import { connect } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const InfoUniform = (props) => {

    const [UniformData, setUniformData] = useState([]);
    const [token, setToken] = useState('');

    useEffect(() => {
        
            if (GetParameter("token") === undefined)
                return;
            else
                setToken(GetParameter("token"))

            props.dispatch(Loading(true))

            const requestOptions = {
                method: "GET",
                headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" }
            }

            fetch("api/uniforms?idCandidate=" + parseInt(props.idCandidate), requestOptions)
                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                .then(json => {
                    setUniformData(json)
                })
                .catch(() => props.dispatch(Toast("Não foi possível obter a informação relativa às fardas.", ToastTypes.Danger, false)))

            props.dispatch(Loading(false))
    }, []);

    

    function buildSizes(objUniform) {

        return (
            <ButtonToolbar>
                {
                    objUniform.sizes.map((result, index) => {
                        return (
                            <ButtonGroup key={"ButtonGroup_" + result.id} className="me-2" style={{ marginLeft: 10 + 'px', minWidth: 50 + 'px', marginTop: 10 + 'px' }}>
                                <Button outline onClick={(e) => handleChangeSize(e, result.id, objUniform.idUniform)} key={"btnSize_" + result.id} className={result.selected ? 'UniformsButtonSelected' : null} >{result.size}</Button>
                            </ButtonGroup>
                        )
                    })
                }
            </ButtonToolbar>
        )
    }

    function handleChangeSize(e, idSize, idUniform) {
        var arruniforms = [...UniformData]
        arruniforms.forEach(a => {
            if (parseInt(a.idUniform) === parseInt(idUniform)) {
                a.sizes.forEach(b => {
                    if (parseInt(idSize) === parseInt(b.id)) {
                        b.selected = true
                    }
                    else {
                        b.selected = false
                    }
                })
            }
        })
        setUniformData(arruniforms)
    }

    function handleSave(e) {

        //validações
        var hasError = false

        UniformData.forEach(a => {
            if (a.sizes.filter(a => a.selected).length !== 1)
                hasError = true;
        })

        if (hasError) {
            props.dispatch(Toast("Por favor responda a todas as questões", ToastTypes.Danger, false))
            return
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(UniformData)
        }


        fetch("/api/uniforms?idCandidate=" + parseInt(props.idCandidate) + "&token=" + token, requestOptions)
            .then(response => {
                if (response.status === 200)
                    window.location.href = window.location.protocol + '//' + window.location.host + '/Success?typeRequest=1'
                else
                    props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde", ToastTypes.Danger, false))

            })
            .catch(() => props.dispatch(Toast("Não foi possível gravar. Por favor tente mais tarde", ToastTypes.Danger, false)))
    }

    return (

        <>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                    <Label><b>Nome: </b></Label>
                    <Label style={{ marginLeft: 10 + 'px' }}>{UniformData.length > 0 ? UniformData[0].candidateName : ''}</Label>
                </Col>
            </Row>
            <br />

            {
                UniformData.map((result, index) => {
                    return (
                        <div key={"dvUniform_" + result.idUniform}>
                            <Row key={"rowUnifor_" + result.idUniform} style={{ marginTop: 30 + 'px' }}>
                                <Col key={"colUnifor_" + result.idUniform} xs="12" sm="12" md="12" lg="12">
                                    <Label key={"lblDesc_" + result.idUniform}>
                                        <b>{result.description}</b>
                                    </Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {buildSizes(result)}
                                </Col>
                            </Row>
                        </div>
                    )
                })
            }



            <Row style={{ marginTop: 50 + 'px' }}>
                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "right" }}>
                    <Button onClick={(e) => handleSave(e)} color="primary"><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                </Col>
            </Row>
        </>
    )
}
const mapStateToProps = state => ({ access_token: state.access_token, profileId: state.profileId, idCandidate: state.idCandidate })
export default connect(mapStateToProps)(InfoUniform)