import React from "react"
import { connect } from "react-redux"
import { Toast, ToastTypes, Loading } from "./global/Utils"
import { Row, Col, Input, Label, Container, Button } from 'reactstrap'
import "./Survey.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPoll } from "@fortawesome/free-solid-svg-icons"

class Survey extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoadingPage: true,
            isSurveyAvailable: false,
            isSurveyCompleted: false,
            header: {
                candidateName: "",
                storeName: ""
            },
            surveyTitle: "",
            surveySummary: "",
            surveyQuestions: [],
            surveyAnswers: []
        }

        this.handleAnswer = this.handleAnswer.bind(this)
        this.handleFinishSurvey = this.handleFinishSurvey.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/surveys", requestOptions)
            .then(response => {
                if (response.status === 200)
                    return response.json();

                if (response.status === 404) {
                    this.setState({ isLoadingPage: false })
                    this.props.dispatch(Loading(false))

                    return null;
                }

                throw response.statusText
            })
            .then(json => {
                if (json === null) return;

                // create the answer's object
                let answer = json.survey.questions.filter(f => f.family !== "group").map(a => { return { questionId: a.id, answer: "" } })
                json.survey.questions.filter(f => f.family === "group").forEach(a => {
                    a.questions.forEach(b => answer.push({ questionId: b.id, answer: "" }))
                })

                this.setState({
                    surveyTitle: json.survey.title,
                    surveySummary: json.survey.summary,
                    surveyQuestions: json.survey.questions,
                    header: json.candidate,
                    surveyAnswers: answer,
                    isLoadingPage: false,
                    isSurveyAvailable: true
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter o detalhe", ToastTypes.Danger, false)))
    }

    buildAnswers(question) {

        switch (question.family) {
            case "group":
                return <></>;
            case "string":
                return <Input type="textarea" data-question={"q" + question.id} style={{ height: 100 + "px" }} onChange={(e) => this.handleAnswer(e)} />
            case "single_choice":
                return (
                    <Container className="preview-question">
                        <Row className="preview-hide-mobile">{question.answers.map((a, index) => <Col key={index} className="text-center"><Label>{a.text}</Label></Col>)}</Row>
                        <Row className="preview-row-margin">{question.answers.map((a, index) =>
                            <Col key={index} data-id={"answer-" + a.id} data-question={"q" + question.id} className="text-center preview-col-normal preview-mouse-hover" xs="12"
                                onClick={(e) => {
                                    let id = e.target.getAttribute("data-id")
                                    document.getElementById(id + "-option").checked = true
                                    this.handleAnswer(e)
                                }}>
                                <Input type="radio" id={"answer-" + a.id + "-option"} name={"single_choice" + question.id} data-id={"answer-" + a.id}
                                    data-question={"q" + question.id} style={{ position: "unset" }} />
                                <Label data-id={"answer-" + a.id} className="preview-show-mobile" style={{ paddingLeft: 5 + "px" }}>{a.text}</Label>
                            </Col>)}
                        </Row>
                    </Container>
                )
            default:
        }
    }

    buildQuestions(questions) {
        if (questions === null)
            return (<></>)

        return questions.map((question, index) => {
            return (
                <div key={index} className="preview-question">
                    <div className="preview-heading">
                        {question.numering === "number" && question.numeringText !== null ? question.numeringText + ". " : ""}
                        {question.numering === "bullet" ? <li>{question.heading}</li> : question.heading}
                    </div>
                    {this.buildAnswers(question)}
                </div>
            )
        })
    }

    handleAnswer(e) {
        let answers = this.state.surveyAnswers
        let questionId = parseInt(e.target.getAttribute("data-question").replace("q", ""))
        let answerId = e.target.getAttribute("data-id")
        let value = e.target.value;

        let index = answers.findIndex(f => f.questionId === questionId)
        if (index === -1)
            return;

        answers[index].answer = (answerId === null ? value : answerId.replace("answer-", ""))

        this.setState({ surveyAnswers: answers })
    }

    handleFinishSurvey() {
        if (this.state.surveyAnswers.filter(f => f.answer === '').length !== 0) {
            this.props.dispatch(Toast("Existem perguntas sem resposta!", ToastTypes.Warning, false))
            return
        }

        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(this.state.surveyAnswers)
        }

        fetch("/api/surveys", requestOptions)
            .then(response => {
                if (response.status !== 200)
                    throw response.statusText;

                this.setState({ isSurveyCompleted: true })
                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível finalizar!", ToastTypes.Danger, false)))
    }

    render() {

        if (this.state.isLoadingPage) return (<></>)

        if (!this.state.isSurveyAvailable)
            return (<div className="text-center" style={{ width: 100 + "%" }}><h2>Questionário não encontrado!</h2></div>)

        if (this.state.isSurveyCompleted)
            return (<div className="text-center" style={{ width: 100 + "%" }}><h2>Questionário finalizado!</h2></div>)

        return (
            <Container>
                <Row>
                    <Col xs="12" sm="12" md="6">
                        <b>NOME:</b><Label style={{ paddingLeft: 5 + "px" }}>{this.state.header.candidateName}</Label>
                    </Col>
                    <Col>
                        <b>RESTAURANTE:</b><Label style={{ paddingLeft: 5 + "px" }}>{this.state.header.storeName}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="preview-question preview-summary" dangerouslySetInnerHTML={{ __html: this.state.surveySummary }} />
                    </Col>
                </Row>
                <div className="preview-height50" />
                {this.state.surveyQuestions.map((question, index) => {
                    return (
                        <Row key={index} className="preview-question-main">
                            <Col className={"preview-padding-bottom10"}>
                                <div>
                                    <div className="preview-question preview-heading" style={{ fontWeight: question.family === "group" ? "bold" : "" }}>
                                        {question.numering === "number" && question.numeringText !== null ? question.numeringText + ". " : ""}
                                        {question.numering === "bullet" ? <li>{question.heading}</li> : question.heading}
                                    </div>
                                    {this.buildAnswers(question)}
                                </div>
                                {question.family === "group" ? this.buildQuestions(question.questions) : null}
                            </Col>
                        </Row>
                    )
                })}
                <Row>
                    <Col className="text-center">
                        <Button color="primary" onClick={() => this.handleFinishSurvey()}><FontAwesomeIcon icon={faPoll} /> Finalizar</Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => ({ access_token: state.access_token })

export default connect(mapStateToProps)(Survey)
