import React, { Component } from "react"
import { Route, Switch } from "react-router"
import Layout from "./components/Layout"
import { connect } from "react-redux"
import "./custom.css"
import Home from "./components/Home"
import Survey from "./components/Survey"
import NoAccess from "./components/NoAccess"
import InfoResume from "./components/InfoResume"
import Disclaimer from "./components/Disclaimer"
import Success from "./components/Success"
import { GetParameter } from "./components/global/Utils"
import InfoDetail from "./components/InfoDetail"
import InfoUniform from "./components/InfoUniform"

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            type: "",
            PageTitle: ''
        }
    }

    componentDidMount() {

        if (GetParameter("token") === undefined)
            return;

        if (parseInt(GetParameter("typeRequest")) === 1) {
            this.setState({ type: 'Success' })
            return
        }

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(GetParameter("token"))
        }

        fetch("api/validate", requestOptions)
            .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
            .then(json => {
                // all ok
                this.props.dispatch({ type: "ACCESS_TOKEN", payload: json.access_token })
                this.props.dispatch({ type: "IDCANDIDATE", payload: json.candidateid })

                //Disclaymer
                if (json.type === 'survey') {
                    this.setState({ type: json.type })
                }
                else {

                    var requestOptions2 = {
                        method: "GET",
                        headers: { "Content-Type": "application/json" }
                    }

                    fetch("api/disclaimer/" + parseInt(json.candidateid), requestOptions2)
                        .then(response => { if (response.status !== 200) throw response.statusText; return response.json(); })
                        .then(result => {
                            if (result) {
                                this.setState({ type: json.type })
                            }
                            else {
                                this.setState({ type: 'Disclaimer' })
                            }
                        })
                        .catch(() => {
                            this.setState({ type: 'NoAccess' })
                        })
                       
                }
            })
            .catch(() => {
                this.setState({ type: 'NoAccess' })
            })
    }

    setComponent() {
        switch (this.state.type) {
            case "survey":
                return Survey
            case "NoAccess":
                return NoAccess
            case "InfoResume":
                return InfoResume
            case "InfoDetail":
                return InfoDetail
            case "InfoUniform":
                return InfoUniform
            case "Disclaimer":
                return Disclaimer
            case "Success":
                return Success
            default:
                return Home
        }
    }

    setPageTitle() {
        switch (this.state.type) {
            case "survey":
                return "Questionário de acompanhamento"
            case "NoAccess":
                return ""
            case "InfoResume":
                return "Informação - Candidato"
            case "InfoDetail":
                return "Informação - Candidato"
            case "InfoUniform":
                return "Fardas"
            case "Success":
                return ""
            default:
                return ""
        }
    }

    render() {
        if (this.state.type === '')
            return (<></>)

        return (
            <Layout PageTitle={this.setPageTitle()}>
                <Switch>
                    <Route exact path="/" component={this.setComponent()} />
                    <Route exact path="" component={this.setComponent()} />
                    <Route component={Home} />
                </Switch>
            </Layout>
        )
    }
}

export default connect()(App)