import React from "react"
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

export default class NoAccess extends React.Component {

    render() {
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <center><FontAwesomeIcon style={{ color: "red" }} icon={faExclamationCircle} /><label style={{ marginLeft: 10 + 'px' }}> A página que está a tentar aceder já não se encontra disponível.</label> </center>
                    </Col>
                </Row>
            </div>
        )
    }
}